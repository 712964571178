


































import { Component, Vue } from "vue-property-decorator";
import PageHeader from "@/admin/components/PageHeader.vue";
import __ from "@/shared/helpers/__";
import { RoleLevel } from "@/shared/modules/role/role.model";
import SearchableField from "@/shared/classes/form/fields/Select/searchable-field";
import _ from "lodash";
import { getNodeApiUrlByVersion } from "@/config";
import { FieldSizes } from "@/shared/classes/form/field";
import { getQueryName } from "@/shared/helpers/query.helper";
import { FilterOperators } from "@/shared/classes/components/data-table/data-table-filter";
import Course from "@/shared/modules/courses/course.model";
import baseHttp from "@/shared/http";
import User from "@/shared/modules/user/user.model";
import { AuthGetters } from "@/shared/store/auth/auth.getters";
import FormField from "@/shared/components/form/FormField.vue";
import CourseBlock from "@/company/components/courses/CourseBlock.vue";

@Component({
  components: { PageHeader, FormField, CourseBlock },
  methods: { __ }
})
export default class AssignedCourses extends Vue {
  http = baseHttp
  courses: Record<string, any> = {}
  panel = [0]

  categorySelect: SearchableField | null = null
  categorySelectValue = null
  searchQuery: string = ''
  debouncedGetCourses!: () => void

  created() {
    this.getCourses(true);
    this.debouncedGetCourses = _.debounce(this.getCourses, 300);

    this.categorySelect = new SearchableField()
      .setKey('responsibleUserUuid')
      .setTitle(__('company.views.courses.assigned.search-category'))
      .loadItems({
        endpoint: `${getNodeApiUrlByVersion()}/course-categories`,
        value: 'uuid',
        title: 'name',
        perPage: 20,
      })
      .setHideDetails(true)
      .setDense(true)
      .setValue(this.categorySelectValue)
      .setOnChange((value) => this.categorySelectValue = value)
      .setSize(FieldSizes.threeTwelfth)

    this.$watch('categorySelectValue', () => this.getCourses())
    this.$watch('searchQuery', () => this.debouncedGetCourses())
  }

  getCourses(setPanels = false) {
    const query = [];
    if (this.categorySelectValue) query.push(`${getQueryName(FilterOperators.equals, 'courseCategoryUuid')}=${this.categorySelectValue}`);
    if (this.searchQuery) query.push(`q=${this.searchQuery}`);

    let endpoint = 'course-assignments/courses-for-user';
    if (this.user.role.level <= RoleLevel.manager) endpoint = 'course-assignments/courses-for-company';

    this.http.get(`${getNodeApiUrlByVersion()}/${endpoint}${query.length ? `?${query.join('&')}` : ''}`)
      .then(response => {
        const groupedCourses: Record<string, any> = {};

        response.data.data.map((course: Course) => {
          if (!(course.courseCategory.uuid in groupedCourses)) {
            groupedCourses[course.courseCategory.uuid] = { category: course.courseCategory.name, courses: [] }
          }
          groupedCourses[course.courseCategory.uuid].courses.push(course);
        });

        this.courses = groupedCourses;
        if (setPanels) {
          this.panel = Object.keys(this.courses).reduce((s: number[], c, i) => ([...s, i]), []);
        }
      })
  }

  get user(): User {
    return this.$store.getters[AuthGetters.getUser]
  }
}
