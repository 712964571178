

























import { Component, Prop, Vue } from "vue-property-decorator";
import __ from "@/shared/helpers/__";
import { CompanyRoutes } from "@/shared/router/company";
import { RoleLevel } from "@/shared/modules/role/role.model";
import { getNodeApiUrlByVersion } from "@/config";
import Course from "@/shared/modules/courses/course.model";
import baseHttp from "@/shared/http";
import RoleCan from "@/shared/components/RoleCan.vue";
import { GlobalActions } from "@/shared/store/global/global.actions";
import CourseParticipantsDialog from "@/company/components/courses/CourseParticipantsDialog.vue";

@Component({
  methods: { __ },
  components: { RoleCan }
})
export default class CourseBlock extends Vue {
  @Prop() course!: Course
  http = baseHttp
  RoleLevel = RoleLevel

  imgSrc = ''

  created() {
    if (this.course.picture) {
      this.http.get(`${getNodeApiUrlByVersion()}/s3-signed-url?key=${this.course.picture}`)
      .then(resp => this.imgSrc = resp.data);
    }
  }

  goToMaterials() {
    if (this.course.currentUserAssignment) {
      this.$router.push({ name: CompanyRoutes.coursesMaterialsIndex, params: { uuid: this.course.currentUserAssignment.uuid } })
    }
  }

  goToTest() {
    if (this.course.currentUserAssignment) {
      this.$router.push({ name: CompanyRoutes.coursesTestIndex, params: { uuid: this.course.currentUserAssignment.uuid } })
    }
  }

  showParticipantsDialog() {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: CourseParticipantsDialog,
      maxWidth: 570,
      persistent: true,
      meta: {
        assignments: this.course.assignments,
      },
    })
  }

  get courseAssignedToCurrentUser() {
    return !!this.course.currentUserAssignment;
  }

  get canTakeATest() {
    return this.course.currentUserAssignment?.status === 'assigned';
  }

  get materialsNotPassed() {
    if (this.course.currentUserAssignment?.lastMaterialViewedIndex === null) return true;
    return this.course.currentUserAssignment.lastMaterialViewedIndex < this.course.materialsAmount - 1;
  }

  get testPassed() {
    return this.course.currentUserAssignment?.status === 'completed';
  }

}
